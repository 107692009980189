.form-container{
  width: 100%;
  height: 100vh;
  display: table;
  margin: 0 auto;
  background-color: #E1F9F4;
}

.login-form{
  background-color: #fff;
  border-radius: 10px;
  width: 28vw;
  height: 58vh;
  margin-left: 550px;
}

.signup-form {
  height: 64vh;
}
.parent-container{
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.logo{
  color: #4C57B6;
  margin-top:20px;
  font-weight: 400;
}

.form-control{
  width: 22vw;
  height: 4vh;
  padding: 22px;
  border-radius: 5px;
  outline: none;
  border: 1px solid #E2E2E2;
}

.btn-primary{
  width: 22vw;
  border: none;
  padding: 14px;
  margin-bottom: 0px;
  border-radius: 5px;
  color: white;
  background-color: rgb(92, 92, 227);
}

.btn-light{
  width: 22vw;
  padding: 0px;
  font-weight: 600;
  color: rgb(92, 92, 227);
  background-color: white;
  border: none;
}

.login-form p{
  font-size: 14px;
  color: #7d7d7d;
}

.signup-link{
  font-weight: 600;
  color: #352D66;
}

.signin-link {
  font-weight: 600;
  color: #352D66;
}