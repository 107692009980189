/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600;700&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Source Sans Pro', sans-serif;
}
body{
  width: 100%;
  height: 100%;
  background-color: #FAFBFC;
}

.nav-bar{
  border-bottom: 5px solid #E1F9F4;
  width: 100%;
  height: 100px;
  margin-bottom: 35px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  /* font-size: 16px; */
  line-height: 25px;
  color: #B4B4C6;
}

.logout{
  float: right;
  margin-top: 30px;
  margin-right: 50px;
  font-family: 'Source Sans Pro';
font-style: normal;
font-weight: 400;
font-size: 22px;
line-height: 28px;
color: #A6A6A6;
}

.user{
  float: left;
  margin-top: 35px;
  margin-left: 350px;
  font-family: 'Source Sans Pro';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 25px;

color: #B4B4C6;
}
.arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}
.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

/* side bar */
ul {
  list-style-type: none;
  margin-top: -135px;
  padding: 0;
  width: 20%;
  background-color: #E1F9F4;;
  position: fixed;
  height: 100%;
  overflow: auto;
  font-family: 'Source Sans Pro';
font-style: normal;
font-weight: 400;
font-size: 22px;
/* line-height: 35px; */
}
li a {
  display: block;
  padding: 8px 16px;
  text-decoration: none;
  text-indent: 15%;
  font-size: 20px;
}

/* p a:hover{
  color: #2289FF;
} */

/* li a:hover{
  color: #2289FF;
} */

h1{
  text-align: center;
  color: black;
}

/* main list */

.button{
  float: right;
  margin-right: 30px;
  background: #6AB4F8;
  color: #FFFFFF;
  border:none;
  height: 40px;
  width: 150px;
  border-radius:20px;
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.13);
}

.button span {
  color: white;
}

.searchbar{
  float: left;
  margin-left: 335px;
  height: 40px;
  background-color: white;
  border-radius: 50px;
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.13);
}

.search-bar button {
  padding: 0px 10px 0px 0px;
  border-radius: 100px;
  border: none;
  color:#6AB4F8;
  background-color: white;
}

::placeholder {
  color: #7D7D7D;
}

#search{
  border-radius: 20px;
  border: none;
  width: 220px;
  height: 40px;
  outline: 0px solid skyblue;
}

#search:hover{
  color: #2289FF;
}

.left-logo a {
  color: #000000;
}

a {
  color: #7D7D7D;
}

/* sign in */




.icons {
  display: flex;
  align-items: center;
  gap: 5px;
}

.icons, a{
  text-decoration: none;
  margin-top: 8px;
}

.left-logo {
  margin-top: 35px;
  margin-bottom: 30px;
}

.nicon {
  margin-left: 25px;
}

table {
  width: 80%;
  border-collapse: separate;
  border-spacing: 0 5px;
  height: 100%;
  display: flex;
  justify-items: center;
  flex-direction: column;
  align-items: center;
  margin-left: 20%;
  margin-top: 7%;
  border-radius: 5px; 
}

th {
  color:#284E91;
  text-align: left;
  font-weight: 400;
}

th, td {
  width: 150px;
  /* text-align: center; */
  padding: 5px;
  padding-left: 10px;
}

tr {
  background-color: white;
  margin: 5px 30px 5px 30px;
  border-radius: 14px;
  padding-left: 20px;
}

th, td {
  padding: 15px;
}

.sold-unsold {
  background-color: #F5FAF5;
  color: #416899;
  padding: 5px 20px 5px 20px;
  border-radius: 6px;
}

/* Nav Logout  */
.logout {
  display: flex;
  gap: 10px;
  margin-top: 35px;
  align-items: center;
  font-size: 16px;
}

/* Left menu items  */
.icons a{
  color: #AAAAAA;
  font-size: 18px;
}

.icons .proper {
  color:#2289FF;
}

.addbutton {
  color: white;
}

.addbutton span {
  margin-right: 10px;
}

.logout {
  color: #A6A6A6;
}


