.form {
  margin: 30px 15px 10px 21%;
  background: #FFFFFF;
  box-shadow: 10px 14px 70px rgba(0, 0, 0, 0.03);
  border-radius: 20px;
}

.containter {
  display: flex;
  justify-content: center;
  gap: 30px;
  
}

.input-field{
  display: flex;
  flex-direction: column;
  margin: 10px;
}

input,select {
  padding: 12px;
  width: 520px;
  height:50px;
  margin-bottom: 10px;
  border: 1px solid #C3C3C3;
  border-radius: 8px;

}

label{
  margin-bottom: 6px;
  font-size: 15px;
  font-weight: 500;
  color: #7D7D7D;
}

select option {
  color: #7D7D7D;
}

::placeholder {
  color: #7D7D7D;
}

.left, .right{
  margin-top: 20px;
}

.footer {
  display: flex;
  justify-content: center;
  margin: 20px 20px;
}

.prev {
  padding: 12px;
  border: none;
  border-radius: 50px;
  color: white;
  background: #6AB4F8;
  box-shadow: 0px 13px 25px rgba(0, 0, 0, 0.15);
  border-radius: 500px;
}

.next {
  padding: 12px;
  border: none;
  border-radius: 50px;
  color: white;
  background: #4C57B6;
  box-shadow: 0px 13px 25px rgba(0, 0, 0, 0.15);
  border-radius: 500px;
}

button{
  margin-bottom: 30px;
}